<template>
  <div class="dev-onboard">
    <Navbar />
    <main>
      <div class="container-wrapper">
        <div class="top-container">
          <div style="width: 30%; cursor: pointer" @click="handleGoBack">
            <img
              src="/img/onboarding/back-icon.svg"
              style="margin-right: 9px"
            />
            <span
              style="
                color: #0055a5;
                font-size: 14px;
                font-family: Moderat;
                font-style: normal;
                font-weight: 500;
              "
              >Go Back</span
            >
          </div>
        </div>
        <div class="page-content-div">
          <div class="center-container">
            <div class="content-title">Welcome to Grupa!</div>
            <div class="sub-title">How do you want to experience Grupa?</div>
            <div class="card-container">
              <div
                v-for="item in options"
                :key="item.id"
                class="option-card"
                @click="handleClick(item.id)"
              >
                <div class="left-content">
                  <div class="card-name">
                    {{ item.name }}
                  </div>
                  <div class="card-content">
                    {{ item.content }}
                  </div>
                </div>
                <div class="right-content">
                  <img src="/img/onboarding/right-arrow.svg" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar/onboarding_navbar";
import { mapState } from "vuex";

export default {
  props: {},
  components: {
    Navbar,
  },

  data: () => ({
    options: [
      {
        id: 1,
        name: "Hire a team",
        content:
          "Go through the next few steps to specify your needs and get matched with the best fit team.",
      },
      {
        id: 2,
        name: "Explore Grupa",
        content:
          "Explore and see what the buzz is all about. Make up your mind later.",
      },
    ],
  }),

  created() {},
  methods: {
    handleClick(id) {
      if (id == 1) {
        this.$Mixpanel.track("Start_Product_Startup_Form", {
          distinct_id: this.currentUser.email,
        });

        this.$router.push({ name: "start_project" });
      } else {
        this.$Mixpanel.track("Founder_Explore_Dashboard", {
          distinct_id: this.currentUser.email,
        });
        this.$router.push({ name: "client_overview" });
      }
    },
    handleGoBack() {
      this.$router.push({ name: "user_type" });
    },
  },
  computed: {
    ...mapState("userManagement", ["currentUser"]),
  },
};
</script>
<style scoped>
* {
  font-family: Moderat;
  font-style: normal;
  font-weight: normal;
  /* #E4E5E7 */
}
.container-wrapper {
}
.top-container {
  margin-top: 48px;
  display: flex;
}
.indicator_progress {
  display: flex;
  justify-content: center;
  margin: 0px;
  width: 37%;
}
.page-content-div {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.center-container {
  margin-top: 60px;
  width: 410px;
  text-align: left;
}
.content-title {
  font-weight: bold;
  font-size: 23px;
  line-height: 28px;
  color: #1b1e22;
}
.sub-title {
  margin-top: 16px;
  font-weight: normal;
  font-size: 16px;
  line-height: 145%;
  color: #979da5;
}
.card-container {
  margin-top: 24px;
}
.option-card {
  margin-top: 24px;
  background: #ffffff;
  border: 1px solid #e4e5e7;
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  padding: 24px 30px 24px;
  justify-content: space-between;
  cursor: pointer;
}
.card-name {
  font-size: 14px;
  line-height: 145%;
  color: #1b1e22;
}
.card-content {
  width: 286px;
  font-size: 14px;
  line-height: 145%;
  color: #979da5;
  margin-top: 8px;
}
.right-content {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
